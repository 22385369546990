import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/styles/common.less";
import "@/assets/styles/element.less";
import '@/utils/rem'
import '@/assets/js/nosd.js'
import emitt from '@/utils/emitt';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from '@/store';


const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.use(store)
app.mount('#app')
app.provide('emitt', emitt);
