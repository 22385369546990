<template>
  <el-config-provider :locale="locale">
      <router-view />
  </el-config-provider>
</template>
<script lang="ts">
import zhCn from "element-plus/dist/locale/zh-cn.min.mjs";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "DataAndManagement",
  setup() {
    const store = useStore();

    

    window.addEventListener("beforeunload", () => {
      // 刷新保存store里面的state
      localStorage.setItem("iot.state", JSON.stringify(store.state));
    });
    return {
      locale: zhCn,
    };
  },
});
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #f3f3f3;
  color: #000000;
  min-height:100vh;
}
.app-nopx{
  font-size: 16px;
  min-width: 1440px;
}
</style>
