const baseSize = 16
function setRem() {
    const clientWidth = document.documentElement.clientWidth;
    // 判断浏览器函数

    const val = 1920;
  
    const scale = clientWidth / val
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 1) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}